body {
    background-color: #213445;
    color: aliceblue;
}

.card {
    background-color: #213445;
    padding: 1rem;
    margin-top: 3rem;
    margin-bottom: 2rem;
    border: 0;
}

.social {
    margin: 20px;
    width: 175px;
    display: inline-flex;
    fill: aliceblue;
    border-radius: 5px;
    background: #000000;
}

.navbar {
    background-color: #213445;
}

.mockup {
    width: auto;
    height: 500px;
    margin: 0 auto;
}

.jumbotron-fluid {
    background-color: #192734;
    align-items: center;
}

footer {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.card {
	color: unset;
	word-wrap: break-word;
}